import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import ReactHtmlParser from "react-html-parser"
import ContactForm from "../components/contactFormCareers"
import SeoBasic from "../components/seo"
import Seo from 'gatsby-plugin-wpgraphql-seo';
import Banner from "../components/bannerImageText"
import getImage from "../functions/getImage"

export const query = graphql`
  query {
    wpPage(slug: {eq: "careers"}) {
    title
    careers {
      header1
      header2
      paragraph1
      paragraph2
      paragraph2image {
        mediaDetails {
          sizes {
            sourceUrl
            width
            name
          }
        }
      }
      headerimage {
        mediaDetails {
          sizes {
            name
            sourceUrl
            width
          }
        }
      }
    }
  }
  seoPage:wpPage(slug: {eq: "careers"}) {
    nodeType
    title
    uri
    seo {
        title
        metaDesc
        focuskw
        metaKeywords
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphImage {
            altText
            sourceUrl
            srcSet
        }
        twitterTitle
        twitterDescription
        twitterImage {
            altText
            sourceUrl
            srcSet
        }
        canonical
        cornerstone
        schema {
            articleType
            pageType
            raw
        }
    }
    }
   allWpJoblisting {
    edges {
      node {
        title
        content
        joblistings {
          jobPdf {
            mediaItemUrl
          }
        }
      }
    }
  } 
  }
`

const GetJobs = ({ items }) => {

  const jobs = items &&
  items.map((item, i) => (

    <div className="job" key={i}>
   
      <h1>{item.node.title}</h1>
      {item.node.joblistings.jobPdf &&
      <a href={item.node.joblistings.jobPdf.mediaItemUrl}>Read More</a>
      }

    </div>
    
  ))

  return jobs
}

const CareersSection2 = ({img, text}) => {

  return (

    <div>

    <div className="homepage_section2_block">

    </div>

    {img &&
    <div className="homepage_section2_image" style={{ backgroundImage: `url("${img}")` }}>
      
    <div className="copy careers">{ReactHtmlParser(text)}</div>

    </div>
    } 

    </div>

  )

}

const IndexPage = ({
  data: {
    wpPage: { title, careers }, seoPage, allWpJoblisting
  },
}) => {

  
  return(
  <Layout>
    {seoPage ?
      <Seo post={seoPage} />
    :
      <SeoBasic title={title} />
    }
    {careers.headerimage && careers.headerimage.mediaDetails &&
    <Banner img={getImage(careers.headerimage.mediaDetails.sizes)} header1={careers.header1} header2={careers.header2} imgclass="careers header" />
    }

    <div className="simpleText careers-copy" style={{backgroundColor:"#191919"}}>

      {ReactHtmlParser(careers.paragraph1)}

    </div>

    <div className="careers-listings">

      <div className="careers-listings-inner" style={{backgroundColor:'#FE912A'}}>
      <GetJobs items={allWpJoblisting.edges} />
      </div>

    </div>



    <div className="homepage_section2 careers" style={{backgroundColor:"#2F2F2F"}}>
      
      {careers.paragraph2image &&  
      <CareersSection2 img={getImage(careers.paragraph2image.mediaDetails.sizes)} text={careers.paragraph2} />
      }
      
    </div>



    <div className="simpleText careers" style={{backgroundColor:"#000000"}}>

      <ContactForm />
    
    </div>

   
  </Layout>
)
}

export default IndexPage
