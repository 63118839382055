import React, { useState } from "react"
import { useForm } from "react-hook-form";
const axios = require('axios');

const ContactForm = () => {

  const { register } = useForm({mode: "onSubmit"});

  const [values, setValues] = useState({
        name: '',
        email: '',
        gdpr: 'no',
        message: ''
  });

  const [fileCV, setFileCV] = useState(null);

  const onChangeFile = e => {

    setFileCV(e.target.files[0])

  }
  
  const handleChange = (e) => {
        // console.log(e.target.value)
        setValues({ ...values, [e.target.id]: e.target.value });
  };

  const isFormValid = () => {
      
      if (!values.name || !values.email || !values.message  || !fileCV) {

      return false;}
      else {
      return true;}

  };

  const [response, setResponse] = useState('')

  const onSubmit = (e) => {

    e.preventDefault()

    // console.log(process.env.GATSBY_CONTACT_EMAIL)

    if (!isFormValid()) {

      setResponse('Please fill in all the fields')

    }
    else{

      // console.log(fileCV)

      let formData = new FormData();
      formData.append("name", values.name)
      formData.append("email", values.email)
      formData.append("gdpr", values.gdpr)
      formData.append("message", values.message)
      formData.append("file", fileCV);
 
      axios.post(process.env.GATSBY_CONTACT_EMAIL_FILE, formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      .then(function (response) {
        console.log(response.data)

        if(response.data==='success'){

          setResponse('Email sent. Thankyou!')
        }

        if(response.data==='failed'){

          setResponse('Email not sent. Please try again')
        }


      })
      .catch(function (error) {
        console.log('error' + error);
      });

    }

  };

  const ShowForm =  (

      <form id='contact-form' onSubmit={onSubmit} method="post">
      <label htmlFor="name">
        
        <input
          className="top"
          type="text"
          name="name"
          id="name"
          placeholder="Name"
          {...register('name', { required: true })}
          onChange={handleChange}
        />
      </label>
      <label htmlFor="email">
        <input
          className="top right"
          type="email"
          name="email"
          id="email"
          placeholder="Email"
          {...register('email', { required: true })}
          onChange={handleChange}
        />
      </label>
      <label htmlFor="question">
        <textarea
          className="bottom"
          {...register('Message', { required: true })}
          name="message"
          id="message"
          rows="3"
          placeholder="Message"
          onChange={handleChange}
        />
      </label>


      <label htmlFor="attach cv">

        <input type="file" id="myfile" name="attachment" onChange={onChangeFile} />

      </label>

      <label className="gdpr" htmlFor="gdpr">
        
        <input
          className="gdpr_input"
          type="checkbox"
          name="gdpr"
          id="gdpr"
          value="yes"
          placeholder="gdpr"
          {...register('GDPR', { required: true })}
          onChange={handleChange}
        />
        You allow us to keep your CV on file for future reference.
      </label>

      <div className="button-holder"><button type="submit">Send</button></div>
    </form>
  
  )

return (

  <>

  <div className="left">
  <h1>Join Us</h1>
  If you have entrepreneurial spirit and the experience to make big ideas happen, we want to hear from you!
  </div>

  <div className="right">
    
    {response}
    
    {response!=='Email sent. Thankyou!' &&
    ShowForm
    }

    </div>

    </>



  )

}

export default ContactForm
